import axios from 'axios';
import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { useNavigate } from 'react-router-dom';
import icon from "./Images/logo192.png";

const firebaseConfig = {
  apiKey: "AIzaSyDLs8k7WzsUTLaqbHHG6TTrqb75gbYNhRg",
  authDomain: "request-mechanic.firebaseapp.com",
  projectId: "request-mechanic",
  storageBucket: "request-mechanic.appspot.com",
  messagingSenderId: "99259155360",
  appId: "1:99259155360:web:8c77726a03b8b5ab489ac5",
  measurementId: "G-ZZ0PWHPYQD"
};

let app;

if (!firebase.apps.length) {
  app = firebase.initializeApp(firebaseConfig);
}

export const db = getFirestore(app);

let messaging;
isSupported().then((supported) => {
  if (supported) {
    messaging = getMessaging(app);
    getToken(messaging, { vapidKey: "BIyETeKClKJtJuC3dQgfrjkEnvxmuC1d1w0T2WEYVtQyeHwxyxMuxqoAEdzBauIElj_njxjqusYzQDf_ZlUhh48" })
      .then(function(token) {
        console.log("FCM Token:", token);
        return token;
      }).catch(function(error) {
        console.log(error);
      });

    onMessage(messaging, (payload) => {
      console.log("notification payload", payload);

      if ('Notification' in window) {
        switch(true) {
          case (payload?.data.is_call === "True" ) &&  (payload?.data.is_voice === "True"):{
            const d = payload.data;
            console.log("payload",payload)
            window.location.href = `/mech-audio-call?id=${d.meeting_id}&model=${d.model}&name=${d.ownerName}&image=${d.owner_image}&email=${d.email}&val=${d.duration}`;
            new Notification(payload.notification.title, { body: payload.notification.body, icon: icon });
          }
            break;
          case (payload?.data.is_call === "False" ) &&  (payload?.data.is_voice === "False"):{
            window.location.href = "/mechanic-dashboard?incoming=true";
              new Notification(payload.notification.title, { body: payload.notification.body, icon: icon });
            }
            break;
          case payload.data?.is_cancelled_call === "True":{
            console.log(payload.data);
              const endCallButton = document.getElementById("callender");
              endCallButton.click()
            }
            break
          case payload.data?.rejected === "false":{
              const endCallButton = document.getElementById("callender");
              endCallButton.click()
            }
            break
          default:
            new Notification(payload.notification.title, { body: payload.notification.body, icon: icon });
        }
      } else {
        console.error("This browser does not support notifications.");
      }
    });
  } else {
    console.error("This browser doesn't support the required APIs for Firebase messaging.");
  }
}).catch((error) => {
  console.error('Error checking if messaging is supported:', error);
});

export default firebase;
export { messaging };

function requestPermission() {
  console.log('Requesting permission...');
  if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      }
    });
  } else {
    console.error("This browser does not support notifications.");
  }
}
