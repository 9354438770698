

import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { CloseCircle} from "iconsax-react";
import { Alert, LinearProgress } from "@mui/material";
import Damp from "./damp";

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

export default function ManualLocation({isScheduledReq,showNextPage,issuesData,location,handleCancel,locationSetter,showNextScreen,showDamp,setMechanicData}){
    const initialData = issuesData
    const [inputValue,setInputValue] = useState(location||'') ;
    const [showResultComp,setShowResultComp] = useState(true);
    const [addressResults,setAddressResults] = useState([]);
    const[payload,setPayload] = useState(initialData);
    const[addressChosen,setAddressChosen] = useState(false);
    const[stateOfResidence,setStateOfResidence] = useState(true);
    const [showAlert,setShowAlert] = useState(false);
    const [fetching,setFetching] = useState(false);
    const [noMechanic,setNoMechanic] = useState(false);
    const [requestId,setRequestId] = useState(null);
    const mapKey = "AIzaSyCGvCxKjSzfDtVS6fxJTUEeUXDI_UaDxGM";
    const requestIdRef = useRef(null);
    
    function handleAdressResultOptionClick(value){
        const isLagos = value.address_components.find(function(addressComponent){
            return addressComponent.types[0] === "administrative_area_level_1" && addressComponent.long_name === "Lagos"
        })
        console.log("handleAdressResultOptionClick",value);
        setInputValue(init=>value.formatted_address);
        setPayload(function(init){ console.log("updating payload with :",value); return {...init,user_address:value.formatted_address}})
        console.log(payload);
        setAddressChosen(init=>true);
        setStateOfResidence(init=>Boolean(isLagos))
        console.log("isLagos",Boolean(isLagos));
    }
    
    useEffect(function(){
        console.log("useEffect payload",payload);
    },[payload])
    
    useEffect(function(){
        if (!stateOfResidence) {
            
        setShowAlert(init=>true)
        setTimeout(() => {
            setShowAlert(init=>false)
        }, 2000);
        }
    },[stateOfResidence])
    
    useEffect(function(){
    
        const timeout = setTimeout(() => {
            handleFetch()
        }, 1000);
    
        return ()=> clearTimeout(timeout)
    },[inputValue])
    
    function handleInputChange(value){
        setInputValue(init=> value);
        setShowResultComp(init=>true)
        setAddressChosen(init=>false);
        setStateOfResidence(init=>true);
    }
    function handleFetch(){
        if (inputValue !== "" ) {        
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${inputValue}&key=${mapKey}`)
            .then(function(raw){
                return raw.json()
            }).then(function(response){
                console.log(response);
                setAddressResults(init=> {
                    if (response.status ==="OK") {
                        return response.results
                    }
                })
            }).catch((error)=>console.log(error))
        }
    }
    function handleMatchMechanic(){
        console.log("match handled");
        if(addressChosen && stateOfResidence){
            const accessToken = localStorage.getItem('authAccessToken');
            if(isScheduledReq){
                setMechanicData(init=>{return {address:inputValue,...payload}})
                showNextPage(init=>true);
            }else{
          
            if (accessToken) {
                setFetching(init=>true);
                axios.post(
                    `${baseUrl}/client/match-mechanic/`,
                    {
                        user_address:inputValue,...payload
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`,
                        },
                      })
                      .then(function(response){
                        // notifyNearbyMechanics(response?.data?.data?.nearest_mechanics);
                        console.log(response?.data?.data?.request.id);
                        setRequestId(init=>response?.data?.data?.request.id)
                        requestIdRef.current = response?.data?.data?.request_status.id
                        return response
                      })
                      .then(response=>{
                        console.log("request status id d= ",response?.data?.data);
                        setMechanicData(init=>{return {id:response?.data?.data?.request?.id}});
                        return new Promise(function(resolve,reject){
                                        PingMechanicCheck(response?.data?.data?.request_status.id,accessToken).then(function(response){

                                            resolve(response);
                                            return response
                                         }).then(function(response){
                                            console.log("inside handle match mechanic");
                                            return response
                                         }).catch(function(error){
                                            console.log(error);
                                         })
                        })
                      }).then(function(response){
                        console.log("end of flow",response);
                        if (response.data.message=="accepted") {
                            console.log({...response?.data?.data?.mechanic});
                            setFetching(init=>false);
                            setMechanicData(init=>{return {...init,...response?.data?.data?.mechanic}})
                            showNextPage(init=>true);
                        }else{
                            setFetching(int=>false)
                            setShowAlert(init=>true);
                            setNoMechanic(init=>true);
                            handlecancel(requestIdRef.current);
                            setTimeout(function(){
                                setShowAlert(init=>false);
                                setNoMechanic(init=>false);
                            },2000)
                        }
                        return response
                      })
                      .catch((error) => {
                        setFetching(int=>false)
                            setShowAlert(init=>true);
                            setNoMechanic(init=>true);
                            setTimeout(function(){
                                setShowAlert(init=>false);
                                setNoMechanic(init=>false);
                            },2000)
                            console.error('no mechanic was found:', error.message);
                      });
                  }else {
                    console.error('Authentication credentials were not provided.');
                  }
                }
    
            }
    }
    function grabMechanicDeviceTokens(mechanics){
        console.log("grabMechanicDeviceTokens all:",mechanics);
      return mechanics.map(function(aMechanic){
        console.log("grabMechanicDeviceTokens",aMechanic.user.email);
            return aMechanic.call_token
        })
        // return tokens
    }
    // function notificator(aToken){
    //     const serverKey = `key=AAAAFxxOg6A:APA91bHp5mTFxILLXludRVwmubxhkEkX4Txik6aFr3YJrAjNx7_JVVwSFKy0eqemID2MeKvpeORxl1RptqzQ_A77ALeZi12TJDaj33bGMxDIwsihquGrFVqfw3eVZCDbVk9zXp0TLKIg`

    //     if(aToken !=""){
    //       console.log("about to create notification payload");
    //           axios.post("https://fcm.googleapis.com/fcm/send",{
    //             to:aToken,
    //             notification:{
    //                 title:"RequestMechanic",
    //                 body:"Your Service is Required"
    //             },
    //             data:{
    //                 is_call:false
    //             }
    //         },{
    //             headers:{
    //                 'Authorization':serverKey,
    //                 'Content-Type':'application/json'
    //             }
    //         }).then(function(response){
    //             console.log("cloud messaging response",response);
    //             return response
    //         }).catch(function(error){
    //             console.log(error);
    //         })
    //     }
    // }
    // function notifyNearbyMechanics(mechanics){
    //    const tokens = grabMechanicDeviceTokens(mechanics);
    //    console.log("tokens",tokens)
    //     tokens.forEach(function(aToken){
    //         console.log(aToken);
    //         notificator(aToken)
    //     })
        
    // }
    async function checkMechanicResponse(request_status_id,accessToken){
        
           return axios.get(`${baseUrl}/client/check_request_status/${request_status_id}/`,{
                headers:{
                'Authorization': `Bearer ${accessToken}`,
                }
            })
          .then((response) => {
            // console.log("new fetch response",response);
            // // setFetching(init=>false);
            // if(String((response?.data?.message)).trim().toLowerCase() == "accepted"){
            //     setMechanicData(init=>{return {...init,...response?.data?.data.mechanic}});
            //     showNextPage(init=>true);
            // }else{
            //     setShowAlert(init=>true);
            //     setNoMechanic(init=>true);
            //     setTimeout(function(){
            //         setShowAlert(init=>false);
            //         setNoMechanic(init=>false);
            //     },2000)
            // }
            //   console.log("match mechanic response",response);
              return response
          }).catch(function(error){
            console.log(error);
          })
    }
    async function PingMechanicCheck(request_status_id,accessToken,runtimes){
        const watchTimes = 36
            return new Promise(function(resolve,reject){
                let watch = 0
                const terval =  setInterval(async () => {
                watch = watch + 1
                const isaccepted = await checkMechanicResponse(request_status_id,accessToken).then(function(response){
                    if (response.data.message == "accepted") {
                        resolve(response)
                        clearInterval(terval);
                        return response
                    }
                    else if (watch >= watchTimes){
                        resolve(response)
                        clearInterval(terval);
                        return response
                    }
                })

            }, 5000);
            })

    }
    function cancel(){
        showNextScreen(init=>false)
        handleCancel(init=>false)
    }
    function handlecancel(id){
        const accessToken = localStorage.getItem('authAccessToken');
    if (accessToken) {
        axios.delete(
            `${baseUrl}/client/user_cancel-request/${id}/` ,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
              })
              .catch((error) => {
                console.error('Error deleting data:', error);
              });
          }else {
            console.error('Authentication credentials were not provided.');
          }
    }
    function handleSearchFocus(){
        setShowResultComp(init=>true);
    }
        return <div className="z-[2] w-screen absolute flex flex-col gap-2 left-0 bottom-0 bg-white rounded-t-3xl p-4">
                    {fetching && <LinearProgress color="success" variant="indeterminate"/>}
                    <p className="text-xl font-black">Let's Match You with the nearest available Mechanic </p>
                    <p className="mb-2 text-slate-400">Rely on our proximity-based system to determine the nearest available mechanic, so you can back on the road faster </p>
                    <p>Current Location</p>
                    <input  onChange={(e)=>handleInputChange(e.target.value)} className="bg-transparent self-center  border-slate-500 border-solid border-[0.02rem] rounded-md py-2 px-2 w-[95%]" value={inputValue} />
                    {showResultComp && <AddressResults inputSetter={handleAdressResultOptionClick} locationSetter={locationSetter} resultCloser={setShowResultComp} results={addressResults || []}/>}
                    <button disabled={!addressChosen} onClick={()=>handleMatchMechanic()} className={`rounded-sm py-2 w-full ${addressChosen ? "bg-green-600" : "bg-slate-200" } text-white pl-4`}>Match with mechanic</button>
                    <button onClick={()=>cancel()} className=" rounded-sm py-2 w-full text-left flex gap-1 "><CloseCircle className="text-orange-800"/>Cancel Request</button>
                    {showDamp && <div onClick={()=>cancel()} ><Damp/></div>}
                    {(showAlert && !stateOfResidence) && <div className="absolute top-[-30vh]" ><Alert severity="error" style={{fontFamily:"SharpSans"}}>Sorry we only Operate in Lagos, Nigeria at the moment</Alert></div>}
                    {(showAlert && noMechanic) && <div className="absolute top-[-30vh]" ><Alert severity="error" style={{fontFamily:"SharpSans"}}>Sorry no Mechanic is currently available</Alert></div>}
        </div>
    }

function AddressResults({results = [],inputSetter,locationSetter,resultCloser}){
        function handleAresultClick(value){
        console.log("handleAresultClick",value);
            inputSetter(value);
            locationSetter(init=>value.formatted_address);
            resultCloser(init=>false);
        }
        return <div className="max-w-[95%] self-center max-h-[30vh] overflow-y-scroll" >
                    {
                        results.map(function(aRes){
                            return <Aresult data={aRes} handleClick={handleAresultClick}/>
                        })
                    }
                    {results.length === 0 && <p>no results found</p>}
                </div>
}

function Aresult({data,handleClick}){
        return <div onClick={()=>handleClick(data)} className="bg-lime-200 w-full px-3 py-2 rounded-md border-[0.02rem] border-black border-solid">
            <p className="w-full overflow-hidden text-ellipsis ">{data.formatted_address}</p>
        </div>
}